// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.n15Axy8IGzyUW_STd1Jy{width:340px;margin-left:-8px;margin-right:-8px}.LgAPzSmwCmevZE2OQHZq{display:flex;flex-wrap:wrap;flex-direction:column;width:100%}.HcE7tVsh4uf6Uji6XNbR{padding-top:8px}.CgmBpmMzF2rkSJaDVJ7F{padding-left:8px;padding-right:8px}._0Y3yvJpAydUJ7ZE61G0L{padding-bottom:4px}.fRxy9lSnTOBrKiQl8Tfd{font-weight:500}.ADUeihbG29YVoznkc6Ul{display:flex;flex-direction:row;column-gap:8px;margin-bottom:4px}.s3oRmY1ZaaGHXd86A2kw{padding:4px 0}.F70oUzrIMQMk_aork9eN{word-wrap:break-word;padding-left:24px}.uoQ8sCemV_AoyJlz7OV0{max-width:332px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden}`, "",{"version":3,"sources":["webpack://./components/ScheduleQualityDetails/ScheduleQualityDetails.module.scss"],"names":[],"mappings":"AAGA,sBACE,WAHM,CAIN,gBAAA,CACA,iBAAA,CAGF,sBACE,YAAA,CACA,cAAA,CACA,qBAAA,CACA,UAAA,CAEA,sBACE,eAhBM,CAmBR,sBACE,gBApBM,CAqBN,iBArBM,CAyBV,uBACE,kBAAA,CAGF,sBACE,eAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,cAAA,CACA,iBAAA,CAGF,sBACE,aAAA,CAGF,sBACE,oBAAA,CACA,iBAAA,CAGF,sBACE,eAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA","sourcesContent":["$padding: 8px;\n$width: 340px;\n\n.root {\n  width: $width;\n  margin-left: -8px;\n  margin-right: -8px;\n}\n\n.container {\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: column;\n  width: 100%;\n\n  &--withTopPadding {\n    padding-top: $padding;\n  }\n\n  &--withLateralPadding {\n    padding-left: $padding;\n    padding-right: $padding;\n  }\n}\n\n.header {\n  padding-bottom: calc($padding / 2);\n}\n\n.header__subText {\n  font-weight: 500;\n}\n\n.row {\n  display: flex;\n  flex-direction: row;\n  column-gap: 8px;\n  margin-bottom: 4px;\n}\n\n.metholodogy {\n  padding: 4px 0;\n}\n\n.text {\n  word-wrap: break-word;\n  padding-left: 24px;\n}\n\n.username {\n  max-width: calc($width - $padding);\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `n15Axy8IGzyUW_STd1Jy`,
	"container": `LgAPzSmwCmevZE2OQHZq`,
	"container--withTopPadding": `HcE7tVsh4uf6Uji6XNbR`,
	"container--withLateralPadding": `CgmBpmMzF2rkSJaDVJ7F`,
	"header": `_0Y3yvJpAydUJ7ZE61G0L`,
	"header__subText": `fRxy9lSnTOBrKiQl8Tfd`,
	"row": `ADUeihbG29YVoznkc6Ul`,
	"metholodogy": `s3oRmY1ZaaGHXd86A2kw`,
	"text": `F70oUzrIMQMk_aork9eN`,
	"username": `uoQ8sCemV_AoyJlz7OV0`
};
export default ___CSS_LOADER_EXPORT___;
