// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wejtngooY9JJ56cWxbqq{width:40%;height:100%;padding:16px;padding-right:0;border:var(--border-weak);min-width:min-content;overflow-y:scroll}.mMf06ikYxQNYzaFyhD7c{padding-right:16px;overflow-y:scroll;height:100%}.mMf06ikYxQNYzaFyhD7c>div{height:100%;max-height:100%}.FMbhHxYdkQ5BrKh351Og{margin-bottom:24px}.FBmX2_mSgStSvAxnJkZe{margin-bottom:16px;width:100%}.xAVpeowzCZwDk2lJ3Rei{white-space:pre}`, "",{"version":3,"sources":["webpack://./components/CheatSheet/CheatSheet.module.scss"],"names":[],"mappings":"AAAA,sBACE,SAAA,CACA,WAAA,CACA,YAAA,CACA,eAAA,CACA,yBAAA,CACA,qBAAA,CACA,iBAAA,CAGF,sBACE,kBAAA,CACA,iBAAA,CACA,WAAA,CAEA,0BACE,WAAA,CACA,eAAA,CAIJ,sBACE,kBAAA,CAGF,sBACE,kBAAA,CACA,UAAA,CAGF,sBACE,eAAA","sourcesContent":[".cheatsheet-container {\n  width: 40%;\n  height: 100%;\n  padding: 16px;\n  padding-right: 0;\n  border: var(--border-weak);\n  min-width: min-content;\n  overflow-y: scroll;\n}\n\n.cheatsheet-innerContainer {\n  padding-right: 16px;\n  overflow-y: scroll;\n  height: 100%;\n\n  > div {\n    height: 100%;\n    max-height: 100%;\n  }\n}\n\n.cheatsheet-item {\n  margin-bottom: 24px;\n}\n\n.cheatsheet-item-small {\n  margin-bottom: 16px;\n  width: 100%;\n}\n\n.code {\n  white-space: pre;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cheatsheet-container": `wejtngooY9JJ56cWxbqq`,
	"cheatsheet-innerContainer": `mMf06ikYxQNYzaFyhD7c`,
	"cheatsheet-item": `FMbhHxYdkQ5BrKh351Og`,
	"cheatsheet-item-small": `FBmX2_mSgStSvAxnJkZe`,
	"code": `xAVpeowzCZwDk2lJ3Rei`
};
export default ___CSS_LOADER_EXPORT___;
