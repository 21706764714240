// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uJmBlCI3zgEVHM0aybs8{display:flex;flex-direction:row;align-items:center;gap:5px}.DOpRO_TkR6GQ7ZjSo4mK{line-height:16px}.BFpYCpQxVOdSqpCLdyNU{font-size:12px;padding:4px 10px 3px 10px}.d07lSgV2Ytw4yzQg7UYQ{background-color:var(--tag-background-danger);color:var(--tag-text-danger);border:1px solid var(--tag-border-danger)}.T2NyeuJosr5oEBOMgu7E{background-color:var(--tag-background-warning);color:var(--tag-text-warning);border:1px solid var(--tag-border-warning)}.JCm1bqnXIFb3uJ8tBaKO{background-color:var(--tag-background-success);color:var(--tag-text-success);border:1px solid var(--tag-border-success)}`, "",{"version":3,"sources":["webpack://./components/ScheduleQuality/ScheduleQuality.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,OAAA,CAGF,sBACE,gBAAA,CAGF,sBACE,cAAA,CACA,yBAAA,CAEA,sBACE,6CAAA,CACA,4BAAA,CACA,yCAAA,CAGF,sBACE,8CAAA,CACA,6BAAA,CACA,0CAAA,CAGF,sBACE,8CAAA,CACA,6BAAA,CACA,0CAAA","sourcesContent":[".root {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 5px;\n}\n\n.quality {\n  line-height: 16px;\n}\n\n.tag {\n  font-size: 12px;\n  padding: 4px 10px 3px 10px;\n\n  &--danger {\n    background-color: var(--tag-background-danger);\n    color: var(--tag-text-danger);\n    border: 1px solid var(--tag-border-danger);\n  }\n\n  &--warning {\n    background-color: var(--tag-background-warning);\n    color: var(--tag-text-warning);\n    border: 1px solid var(--tag-border-warning);\n  }\n\n  &--primary {\n    background-color: var(--tag-background-success);\n    color: var(--tag-text-success);\n    border: 1px solid var(--tag-border-success);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `uJmBlCI3zgEVHM0aybs8`,
	"quality": `DOpRO_TkR6GQ7ZjSo4mK`,
	"tag": `BFpYCpQxVOdSqpCLdyNU`,
	"tag--danger": `d07lSgV2Ytw4yzQg7UYQ`,
	"tag--warning": `T2NyeuJosr5oEBOMgu7E`,
	"tag--primary": `JCm1bqnXIFb3uJ8tBaKO`
};
export default ___CSS_LOADER_EXPORT___;
